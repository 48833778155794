import {IdleState} from '../states/idle.state';
import {FiniteMachineState} from '../states/finite-machine.state';
import {Anim01State} from '../states/Anim_01.state';
import {Anim02State} from '../states/Anim_02.state';
import {Anim03State} from '../states/Anim_03.state';
import {Anim04State} from '../states/Anim_04.state';
import {Anim05State} from '../states/Anim_05.state';
import {Anim06State} from '../states/Anim_06.state';
import {Anim07State} from '../states/Anim_07.state';
import {Anim08State} from '../states/Anim_08.state';
import {Anim09State} from '../states/Anim_09.state';
import {Anim010State} from '../states/Anim_010.state';

export class AvatarFsm extends FiniteMachineState {
    private _proxy: any;

    constructor(proxy: any) {
        super();
        this._proxy = proxy;
        this._Init();
    }

    _Init() {
        this._AddState('idle', IdleState);
        this._AddState('Anim_01', Anim01State);
        this._AddState('Anim_02', Anim02State);
        this._AddState('Anim_03', Anim03State);
        this._AddState('Anim_04', Anim04State);
        this._AddState('Anim_05', Anim05State);
        this._AddState('Anim_06', Anim06State);
        this._AddState('Anim_07', Anim07State);
        this._AddState('Anim_08', Anim08State);
        this._AddState('Anim_09', Anim09State);
        this._AddState('Anim_10', Anim010State);
    }
}
