import React, {CSSProperties, useEffect} from 'react';
import {IonContent, IonIcon, IonLabel, IonPage, isPlatform} from '@ionic/react';
import './Tab1.css';
import {useSwipeable} from 'react-swipeable';
import {AvatarController} from '../avatar/controller/avatar.controller';
import {chevronBack, chevronForward} from "ionicons/icons";
import card02 from '../assets/cards/2-behind.jpg';
import card03 from '../assets/cards/3-some.jpg';
import card05 from '../assets/cards/5-how.jpg';
import card07 from '../assets/cards/7-who.jpg';
import card08 from '../assets/cards/8-does.jpg';
import card09 from '../assets/cards/9-thursday.jpg';

// @ts-ignore
import video01 from '../assets/videos/1-under.mp4';
// @ts-ignore
import video02 from '../assets/videos/2-behind.mp4';
// @ts-ignore
import video03 from '../assets/videos/3-some.mp4';
// @ts-ignore
import video04 from '../assets/videos/4-where.mp4';
// @ts-ignore
import video05 from '../assets/videos/5-how.mp4';
// @ts-ignore
import video06 from '../assets/videos/6-when.mp4';
// @ts-ignore
import video07 from '../assets/videos/7-who.mp4';
// @ts-ignore
import video08 from '../assets/videos/8-does.mp4';
// @ts-ignore
import video09 from '../assets/videos/9-thursday.mp4';
// @ts-ignore
import video10 from '../assets/videos/10-his.mp4';

// @ts-ignore
import audio01 from '../assets/audios/2-behind.mp3';
// @ts-ignore
import audio02 from '../assets/audios/3-some.mp3';
// @ts-ignore
import audio03 from '../assets/audios/5-how.mp3';
// @ts-ignore
import audio04 from '../assets/audios/7-who.mp3';
// @ts-ignore
import audio05 from '../assets/audios/8-does.mp3';
// @ts-ignore
import audio06 from '../assets/audios/9-thursday.mp3';

function badgeStyle(index: number): CSSProperties {
    return {
        left: `calc(${index}*(80vw + 20px))`,
        cursor: 'pointer',
    };
}

let avatar: AvatarController;

function createAvatar(): void {
    avatar = new AvatarController(window.innerWidth, window.innerHeight);
}

const Tab1: React.FC = () => {
    useEffect(() => {
        createAvatar();
        /*const renderer = new WebGLRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        // @ts-ignore
        document.querySelector('#avatarScene').appendChild(renderer.domElement);

        const scene = new Scene();
        const camera = new PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
        );
        const orbit = new OrbitControls(camera, renderer.domElement);
        orbit.target.set(0, 10, 0);
        orbit.update();

        const ambientLight = new AmbientLight(0x333333);
        scene.add(ambientLight);
        const directionalLight = new DirectionalLight(0xFFFFFF);
        scene.add(directionalLight);

        const video = document.createElement('video');
        video.style.width = `${window.innerWidth}px`;
        video.style.height = `${window.innerHeight}px`;
        video.src = 'https://anenglishisland.com/elearning/assets/cards/videos/G6.mp4';
        scene.background = new VideoTexture(video);


        const avatarLoader = new FBXLoader();
        avatarLoader.load(
            require('../assets/animations/idle.fbx'),
            (fbx) => {
                /!*fbx.traverse(function (child) {
                    if (child.isObject3D) {
                        child.castShadow = true;
                        child.receiveShadow = true;
                        child.frustumCulled = false;
                    }
                });*!/
                scene.add(fbx);
            }
        )
        renderer.render(scene, camera);*/

    }, []);

    const frasi = {
        // Anim_01: 'Frase 01',
        Anim_01: 'Frase 01',
        Anim_02: 'Frase 02',
        // Anim_04: 'Frase 04',
        Anim_03: 'Frase 03',
        // Anim_06: 'Frase 06',
        Anim_04: 'Frase 04',
        Anim_05: 'Frase 05',
        Anim_06: 'Frase 06',
        // Anim_10: 'Frase 10',
    };

    /*const cards = {
        Anim_01: '1-under.jpg',
        Anim_02: '2-behind.jpg',
        Anim_03: '3-some.jpg',
        Anim_04: '4-where.jpg',
        Anim_05: '5-how.jpg',
        Anim_06: '6-when.jpg',
        Anim_07: '7-who.jpg',
        Anim_08: '8-does.jpg',
        Anim_09: '9-thursday.jpg',
        Anim_10: '10-his.jpg',
    }*/

    const frasiArrayValues = Object.values(frasi);

    /*const cardsArrayValues = Object.values(cards);*/

    const cards = [card02, card03, card05, card07, card08, card09];
    const videos = [video02, video03, video05, video07, video08, video09];
    const audios = [audio01, audio02, audio03, audio04, audio05, audio06];

    const [fraseAttiva, setFraseAttiva] = React.useState(0);

    const containerStyle: CSSProperties = {
        width: `calc(${Object.keys(frasi).length}*(80vw + 20px))`,
        left: `calc(-${fraseAttiva}*(80vw + 20px) + 10vw - 10px)`,
    };

    const swipeSentence = useSwipeable({
        onSwipedLeft: () => {
            if (fraseAttiva < Object.keys(frasi).length - 1) {
                setFraseAttiva(fraseAttiva + 1);
                console.log(`Anim_0${fraseAttiva + 2}`)
                avatar.loadState(`Anim_0${fraseAttiva + 2}`) // qui fraseAttiva si è ancora incrementata di +1, quindi dovendo aggiungere +1 di default (perché fraseAttiva va da 0 a 9 ma i file da 1 a 10), faccio +2
            }
        },
        onSwipedRight: () => {
            if (fraseAttiva > 0) {
                setFraseAttiva(fraseAttiva - 1);
                // avatar.loadState(`Anim_0${fraseAttiva}`) // qui fraseAttiva si è ancora decrementata di -1, quindi dovendo aggiungere +1 di default (perché fraseAttiva va da 0 a 9 ma i file da 1 a 10), faccio +1 -1 => 0, quindi fraseAttiva
            }
        },
        trackMouse: true,
    });

    return (
        <IonPage className={'content-style'}>
            <IonContent>
                <audio src={audios[fraseAttiva]} loop={false}></audio>
                <div className="uk-card uk-card-default uk-card-body webcamContainer"
                     style={{height: '100%', width: '100%', backgroundColor: 'var(--ion-color-primary)'}}>
                    <div id="avatarScene"/>
                </div>
                <div className={'card-container'}>
                    <img src={cards[fraseAttiva]} alt={frasiArrayValues[fraseAttiva]} style={{height: '280px'}}/>
                </div>
                <div className={'video-container'} style={{padding: '10px'}}>
                    <video height="180" src={videos[fraseAttiva]}></video>
                </div>
                <div style={containerStyle} className={'container-style'} {...swipeSentence}>
                    {
                        Object.keys(frasi).map((key: string, index: number) =>
                            <div style={badgeStyle(index)} className={'badge-style'}
                                 onClick={
                                     event => {
                                         console.log(key, index);
                                         avatar.setState(key, event);
                                     }
                                 }>
                                <IonLabel style={{fontSize: '18pt', fontWeight: 'bold'}}>
                                    {frasiArrayValues[index]}
                                </IonLabel>
                            </div>,
                        )
                    }
                </div>
                <div className={'left-button'}
                     onClick={event => {
                         setFraseAttiva(fraseAttiva > 0 ? fraseAttiva - 1 : fraseAttiva);
                         // avatar.loadState(`Anim_0${(fraseAttiva > 0 ? fraseAttiva - 1 : fraseAttiva) + 1}`, event)
                     }}>
                    <IonIcon icon={chevronBack}
                             style={{
                                 color: (isPlatform('desktop') || isPlatform('tablet')) ? 'var(--ion-color-primary)' : 'transparent',
                                 fontSize: '100px',
                             }}/>
                </div>
                <div className={'right-button'}
                     onClick={event => {
                         setFraseAttiva(fraseAttiva < Object.keys(frasi).length - 1 ? fraseAttiva + 1 : fraseAttiva);
                         avatar.loadState(`Anim_0${(fraseAttiva < Object.keys(frasi).length - 1 ? fraseAttiva + 1 : fraseAttiva) + 1}`, event)
                     }}>
                    <IonIcon icon={chevronForward}
                             style={{
                                 color: (isPlatform('desktop') || isPlatform('tablet')) ? 'var(--ion-color-primary)' : 'transparent',
                                 fontSize: '100px',
                             }}/>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Tab1;
