import {
    AmbientLight,
    AnimationMixer, CircleGeometry, ConeGeometry, CubeTextureLoader, CylinderGeometry,
    DirectionalLight, Mesh, MeshStandardMaterial,
    PCFSoftShadowMap,
    PerspectiveCamera, PlaneGeometry,
    Scene,
    sRGBEncoding,
    Vector3,
    WebGLRenderer,
} from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader';
import {AvatarInitializer} from '../interfaces/avatar-initializer';
import {AvatarInizializerController} from './avatar-inizializer.controller';
import React from 'react';

export class AvatarController {
    private _threejs!: WebGLRenderer;
    private _camera!: PerspectiveCamera;
    private _scene!: Scene;
    private _mixers!: AnimationMixer[];
    private _previousRAF: any;
    private _controls!: AvatarInizializerController;

    constructor(sceneWidth: number, sceneHeight: number) {
        this._Initialize(sceneWidth, sceneHeight);
    }

    _Initialize(sceneWidth: number, sceneHeight: number) {
        this._threejs = new WebGLRenderer({
            antialias: true,
        });
        this._threejs.outputEncoding = sRGBEncoding;
        this._threejs.shadowMap.enabled = true;
        this._threejs.shadowMap.type = PCFSoftShadowMap;
        this._threejs.setPixelRatio(window.devicePixelRatio);
        this._threejs.setSize(sceneWidth, sceneHeight);

        // document.body.appendChild(this._threejs.domElement);
        // @ts-ignore
        document.getElementById('avatarScene').appendChild(this._threejs.domElement);

        // TODO per ora non faccio resize e ci sta che non mi serva mai
        window.addEventListener('resize', () => {
            this._OnWindowResize();
        }, false);

        // Camera
        const fov = 80;
        const aspect = sceneWidth / sceneHeight;
        const near = 1.0;
        const far = 500.0;
        this._camera = new PerspectiveCamera(fov, aspect, near, far);
        this._camera.position.set(0, 15, 20);

        // Scena
        this._scene = new Scene();

        let light = new DirectionalLight(0xFFFFFF, 1.0);
        light.position.set(-350, 150, 150);
        light.target.position.set(0, 0, 0);
        light.castShadow = true;
        light.shadow.bias = -0.001;
        light.shadow.mapSize.width = 4096;
        light.shadow.mapSize.height = 4096;
        light.shadow.camera.near = 0.1;
        light.shadow.camera.far = 500.0;
        light.shadow.camera.near = 0.5;
        light.shadow.camera.far = 500.0;
        light.shadow.camera.left = 50;
        light.shadow.camera.right = -50;
        light.shadow.camera.top = 50;
        light.shadow.camera.bottom = -50;
        this._scene.add(light);

        // @ts-ignore
        light = new AmbientLight(0xFFFFFF, 0.25);
        this._scene.add(light);

        // Controllo orbita con Mouse e Frecce
        const controls = new OrbitControls(this._camera, this._threejs.domElement);
        controls.target.set(0, 10, 0);
        controls.update();

        // Texture ambiente
        const loader = new CubeTextureLoader();
        const texture = loader
            .load([
                require("../../assets/environment/posx.jpg"),
                require("../../assets/environment/negx.jpg"),
                require("../../assets/environment/sopra.jpg"),
                require("../../assets/environment/sotto.jpg"),
                require("../../assets/environment/posz.jpg"),
                require("../../assets/environment/negz.jpg"),
            ]);
        texture.encoding = sRGBEncoding;
        this._scene.background = texture;

        // isola
        /*const island = new FBXLoader();
        const path = require('../../assets/island/desert_island_2.fbx');
        island.load(`${path}`, (fbx) => {
            console.log(fbx)
            fbx.scale.setScalar(20);
            // fbx.position.set(0, 0, -60);
            // fbx.rotation.set(0, 190, 0);
            this._scene.add(fbx);
        });*/


        // TODO piano "pavimento"
        const plane = new Mesh(
            // new PlaneGeometry(100, 100, 10, 10),
            new CircleGeometry(30, 100),
            new MeshStandardMaterial({
                color: 0xffeaa7,
            }));
        plane.castShadow = false;
        plane.receiveShadow = true;
        plane.rotation.x = -Math.PI / 2;
        this._scene.add(plane);

        this._mixers = [];
        this._previousRAF = null;

        this._LoadAnimatedModel();
        this._RAF();
    }

    _LoadAnimatedModel() {
        const params: AvatarInitializer = {
            camera: this._camera,
            scene: this._scene,
        };
        // Associazione al controller del personaggio
        this._controls = new AvatarInizializerController(params);
    }

    public setState = (state: string, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
        }
        this._controls._UpdateState(state);
    };

    public loadState = (state: string, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
        }
        if (!this._controls._animations[state]) {
            this._controls._LoadState(state);
        }
    };

    _LoadAnimatedModelAndPlay(path: string, modelFile: string, animFile: string, offset: Vector3) {
        const loader = new FBXLoader();
        // loader.setPath(path);
        loader.load(require(`${path}${modelFile}`), (fbx) => {
            fbx.scale.setScalar(0.1);
            fbx.traverse(c => {
                c.castShadow = true;
            });
            fbx.position.copy(offset);

            const anim = new FBXLoader();
            anim.setPath(path);
            anim.load(require(`${path}${animFile}`), (anim) => {
                const m = new AnimationMixer(fbx);
                this._mixers.push(m);
                const idle = m.clipAction(anim.animations[0]);
                idle.play();
            });
            this._scene.add(fbx);
        });
    }

    _OnWindowResize() {
        this._camera.aspect = window.innerWidth / window.innerHeight;
        this._camera.updateProjectionMatrix();
        this._threejs.setSize(window.innerWidth, window.innerHeight);
    }

    _RAF() {
        requestAnimationFrame((t) => {
            if (this._previousRAF === null) {
                this._previousRAF = t;
            }

            this._RAF();

            this._threejs.render(this._scene, this._camera);
            this._Step(t - this._previousRAF);
            this._previousRAF = t;
        });
    }

    _Step(timeElapsed: number) {
        const timeElapsedS = timeElapsed * 0.001;
        /*if (this._mixers) {
         this._mixers.map(m => m.update(timeElapsedS));
         }*/

        if (this._controls) {
            this._controls.Update(timeElapsedS);
        }
    }
}
