export class FiniteMachineState {
    _currentState: {
        Name: string;
        Update: (arg0: any, arg1: any) => void;
        Exit(): void;
        _parent: FiniteMachineState;
    } | null;
    private readonly _states: { [x: string]: any; };

    constructor() {
        this._states = {};
        this._currentState = null;
    }

    _AddState(name: string, type: any) {
        this._states[name] = type;
    }

    SetState(name: string) {
        console.log('stato', name);
        document.querySelector('audio')!.play().then();
        const {_currentState: _currentState1, _states} = this;
        const prevState = _currentState1;

        if (prevState) {
            if (prevState.Name === name) {
                return;
            }
            prevState.Exit();
        }

        let state: any;
        state = new _states[name](this);

        this._currentState = state;
        state.Enter(prevState);
    }

    Update(timeElapsed: any, input: any) {
        if (this._currentState) {
            this._currentState.Update(timeElapsed, input);
        }
    }
}
